
export default defineNuxtRouteMiddleware(async (to) => {
  const $auth = useAuth();

  const currentPrefix = $auth.strategy.token.scheme.options.token.prefix;

  // If the token prefix is default, we need to change it to the workspace slug
  // because now we have a workspace slug to use
  if (currentPrefix === '_token.') {
    const workspaceSlug = to.params.tenantSlug || '';
    $auth.strategy.token.scheme.options.token.prefix = `${workspaceSlug}_token.`;

    await $auth.fetchUserOnce();
  }
});
